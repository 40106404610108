import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_sidebar_item = _resolveComponent("van-sidebar-item");
  const _component_van_sidebar = _resolveComponent("van-sidebar");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  const _component_footerTabber = _resolveComponent("footerTabber");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_row, {
    style: {
      "position": "relative",
      "left": "0",
      "top": "60px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_sidebar, {
        style: _normalizeStyle({
          height: $setup.domHeight
        }),
        class: "sidebarSty",
        modelValue: $setup.sidebarActiveVal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.sidebarActiveVal = $event),
        onChange: $setup.onChangeFn
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sidebarItemObj.arr, n => {
          return _openBlock(), _createBlock(_component_van_sidebar_item, {
            title: n.sidebarName
          }, null, 8, ["title"]);
        }), 256))]),
        _: 1
      }, 8, ["style", "modelValue", "onChange"])]),
      _: 1
    }), _createVNode(_component_van_col, {
      span: "18"
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        class: "sideGoodsWrap",
        style: _normalizeStyle({
          height: $setup.domHeight
        })
      }, null, 4)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_footerTabber)], 64);
}