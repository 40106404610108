import { ref, reactive, onMounted } from 'vue';
export default {
  name: 'category',
  data() {
    return {
      msg: '分类页'
    };
  },
  components: {
    footerTabber
  },
  setup() {
    // 设置dom的滚动的高度
    let domHeight = ref(0);
    onMounted(() => {
      console.log(window.innerHeight);
      domHeight.value = window.innerHeight - 120 + 'px';

      // 当页面大小变化的时候
      window.addEventListener('resize', function () {
        console.log(window.innerHeight);
        domHeight.value = window.innerHeight - 120 + 'px';
      });
    });

    // 侧边导航栏，索引
    let sidebarActiveVal = ref(0);

    // 侧边栏，切换事件
    const onChangeFn = inx => {
      // console.log( inx )
    };

    // 侧边栏的axios
    let sidebarItemObj = reactive({
      arr: []
    });
    requestFn({
      url: '/sidebarItemName',
      method: 'get'
    }).then(_d => {
      // console.log( _d.data )
      sidebarItemObj.arr = _d.data;
    });
    return {
      sidebarActiveVal,
      onChangeFn,
      sidebarItemObj,
      domHeight
    };
  }
};